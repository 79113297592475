import React from "react"
import { DropDownList } from "@progress/kendo-react-dropdowns"
import styled from "styled-components"

function CustomFilterCell(props) {
  const {
    data = [
      { tab: "True", value: "true" },
      { tab: "False", value: "false" },
    ],
    defaultItem = "All",
    value = { value: "" },
    dropdownChange = () => "",
    onClearButtonClick = () => "",
    field,
  } = props || {}
  const hasValue = (value) => Boolean(value && value !== defaultItem)
  let dropDownItems = ["All"]
  data.map((item) => {
    dropDownItems.push(item.tab)
    return item
  })
  const selectedItemIndex = data.findIndex((item) => item.value === value.value)

  return (
    <div className="k-filtercell">
      <DropDownList
        data={dropDownItems}
        onChange={(e) => {
          const selectedValue =
            e.value !== "All"
              ? data[data.findIndex((item) => item.tab === e.value)].value
              : "All"
          dropdownChange(selectedValue, field)
        }}
        value={
          selectedItemIndex !== -1 ? data[selectedItemIndex].tab : defaultItem
        }
        // defaultItem={defaultItem}
      />
      {hasValue(value.value) ? (
        <button
          className="k-button k-button-icon k-clear-button-visible"
          title="Clear"
          style={{ visibility: hasValue(value.value) }}
          onClick={() => {
            onClearButtonClick(field)
          }}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      ) : null}
    </div>
  )
}

export default CustomFilterCell
