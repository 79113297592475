import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

export const StyledOperationHoursKitMainWrapper = styled(Grid)`
  min-width: 1000px;
  background: #fff;
  padding: 30px;
  .cls-oph-itm-day-kit {
    min-width: 180px;
    max-width: 180px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
  }
  .cls-oph-itm-day {
    display: flex;
    flex-direction: row;
    min-height: 80px;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 1px solid #dfe4e6;

    .cls-oph-group-time {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 10px;
      row-gap: 10px;
      width: 100%;
    }
    .cls-oph-itm-time {
      display: flex;
      flex-direction: row;
    }
  }
`
