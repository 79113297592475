import { TimePicker } from "@material-ui/pickers"
import {
  makeStyles,
  Grid,
  withStyles,
  IconButton,
  Button,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core"
import * as IconsGallery from "@iconsGallery"
import _ from "lodash"

const moment = require("moment")

const CustomTimePicker = withStyles((theme) => ({
  root: {
    height: "15px",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: "var(--primaryColor)",
    },
    "& .MuiFormHelperText-contained": {
      width: "100%",
    },
  },
}))(TimePicker)

export const renderContainer = (props) => {
  const {
    handleTimeChange = () => "",
    handleTimeDelete = () => "",
    handleTimeAdd = () => "",
    getOperationHoursList,
    maxTimeLimit,
    edit,
    errors,
    touched,
  } = props
  const { business_hrs = "" } = errors

  const getMaxLength = (itm) => {
    let checkMaxTimeLengthCount = itm
    checkMaxTimeLengthCount = checkMaxTimeLengthCount.map((itmInn) => {
      let kit = []
      let test = itmInn?.times.map((innerKit) => {
        if (_.isEmpty(innerKit) === false) {
          kit.push(innerKit)
        }
      })
      return kit.length
    })
    checkMaxTimeLengthCount = Math.max.apply(null, checkMaxTimeLengthCount)
    return checkMaxTimeLengthCount
  }

  const getActualTime = (time) => {
    let timeArray = time.split(":")
    let hr = parseInt(timeArray[0])
    let min = parseInt(timeArray[1])
    let currentDate = new Date()
    currentDate.setHours(hr)
    currentDate.setMinutes(min)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)
    return currentDate
  }

  return (
    <div>
      {getOperationHoursList.map((itm, weekIndex) => {
        let checkMaxTimeLengthCount = getMaxLength(itm)

        return (
          <div>
            {itm.map((innerItm, dayIndex) => {
              const { times = [], errorsArr = [] } = innerItm
              let isEmptyTime = times.filter((itmKit) => !_.isEmpty(itmKit))
              return (
                <>
                  <div className="cls-oph-itm-day">
                    <div
                      style={{
                        display: "flex",
                      }}
                      className="cls-oph-itm-day-kit"
                    >
                      <div style={{ marginRight: 10 }}>
                        {innerItm?.day || "-"}
                      </div>
                      {isEmptyTime.length > 0 === false && edit && (
                        <Button
                          disabled={!edit}
                          style={{
                            width: 55,
                            height: 36,
                            border: "2px solid #A62991",
                          }}
                          onClick={(e) => {
                            handleTimeAdd({
                              e,
                              dayName: innerItm?.day,
                              weekIndex,
                              dayIndex,
                            })
                          }}
                        >
                          <IconsGallery.AddIcon style={{ color: "#A62991" }} />
                          <span
                            style={{ fontWeight: "bold", color: "#A62991" }}
                          >{`Add`}</span>
                        </Button>
                      )}
                    </div>
                    <div className="cls-oph-group-time">
                      {isEmptyTime.length > 0 ? (
                        <>
                          {times.map((timeItm, timeIndex) => {
                            let isEmpty = _.isEmpty(timeItm)
                            const { time_from = "", time_to = "" } = timeItm
                            let startTime = moment(time_from)
                              .utc()
                              .format("HH:mm")
                            let startActualTime = getActualTime(startTime)
                            let endTime = moment(time_to).utc().format("HH:mm")
                            let endActualTime = getActualTime(endTime)
                            return (
                              <>
                                {isEmpty === false && (
                                  <div className="cls-oph-itm-time">
                                    <div style={{ marginRight: 25 }}>
                                      <CustomTimePicker
                                        helperText={
                                          Boolean(
                                            errorsArr[timeIndex]?.errFrom
                                          ) && errorsArr[timeIndex]?.errFrom
                                        }
                                        error={Boolean(
                                          errorsArr[timeIndex]?.errFrom
                                        )}
                                        disabled={!edit}
                                        fullWidth
                                        variant="dialog"
                                        emptyLabel=""
                                        inputVariant="outlined"
                                        size="medium"
                                        margin="dense"
                                        label="Open Time"
                                        value={
                                          startActualTime
                                          // new Date(
                                          // `Wed Mar 20 2024 ${startTime}:00 GMT+0530 (India Standard Time)`)
                                        }
                                        onChange={(e) => {
                                          handleTimeChange({
                                            e,
                                            type: "time_from",
                                            dayName: innerItm?.day,
                                            timeIndex,
                                            weekIndex,
                                            dayIndex,
                                          })
                                        }}
                                        InputProps={{
                                          style: { width: "125px" },
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconsGallery.ClockIcon
                                                style={{
                                                  color: "var(--primaryColor)",
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <CustomTimePicker
                                        helperText={
                                          Boolean(
                                            errorsArr[timeIndex]?.errTo
                                          ) && errorsArr[timeIndex]?.errTo
                                        }
                                        error={Boolean(
                                          errorsArr[timeIndex]?.errTo
                                        )}
                                        disabled={!edit}
                                        fullWidth
                                        variant="dialog"
                                        emptyLabel=""
                                        inputVariant="outlined"
                                        size="medium"
                                        margin="dense"
                                        label="Close Time"
                                        value={
                                          endActualTime
                                          // new Date(
                                          //   `Wed Mar 20 2024 ${endTime}:00 GMT+0530 (India Standard Time)`
                                          // )
                                        }
                                        onChange={(e) => {
                                          handleTimeChange({
                                            e,
                                            type: "time_to",
                                            dayName: innerItm?.day,
                                            timeIndex,
                                            weekIndex,
                                            dayIndex,
                                          })
                                        }}
                                        InputProps={{
                                          style: { width: "125px" },
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconsGallery.ClockIcon
                                                style={{
                                                  color: "var(--primaryColor)",
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                    {times.length > 0 && edit && (
                                      <IconButton
                                        style={{ width: 45, height: 45 }}
                                        disabled={!edit}
                                        onClick={(e) => {
                                          handleTimeDelete({
                                            e,
                                            dayName: innerItm?.day,
                                            timeIndex,
                                            weekIndex,
                                            dayIndex,
                                          })
                                        }}
                                      >
                                        <IconsGallery.DeleteOutlined
                                          style={{
                                            color: "var(--primaryColor)",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                )}
                              </>
                            )
                          })}
                          {isEmptyTime.length < maxTimeLimit && edit && (
                            <Button
                              disabled={!edit}
                              style={{
                                margin: "10px 10px",
                                maxWidth: 26,
                                minWidth: 26,
                                width: 26,
                                height: 26,
                                border: "2px solid #1665C0",
                              }}
                              onClick={(e) => {
                                handleTimeAdd({
                                  e,
                                  dayName: innerItm?.day,
                                  weekIndex,
                                  dayIndex,
                                })
                              }}
                            >
                              <IconsGallery.AddIcon
                                style={{ color: "#1665C0" }}
                              />
                            </Button>
                          )}
                        </>
                      ) : (
                        <div
                          style={{
                            minWidth: 280,
                            width: "100%",
                            height: 40,
                            background: "#DFE4E6",
                            border: "1px solid #C5CCD0",
                            borderRadius: 4,
                            padding: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gridColumn: `1 / span ${checkMaxTimeLengthCount}`,
                          }}
                        >
                          <span>{`Closed`}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )
            })}
            {business_hrs &&
              business_hrs !== undefined &&
              business_hrs !== "" && (
                <FormHelperText style={{ color: "red" }}>
                  {business_hrs}
                </FormHelperText>
              )}
          </div>
        )
      })}
    </div>
  )
}

export const renderHeaderContainer = (props) => {
  const {
    handleTimeChange = () => "",
    handleTimeDelete = () => "",
    handleTimeAdd = () => "",
    getOperationHoursList,
    maxTimeLimit,
    errors = "",
  } = props

  const { business_hrs = "" } = errors
  const getMaxLength = () => {
    let checkMaxTimeLengthCount =
      getOperationHoursList.length > 0 ? getOperationHoursList[0] : []
    checkMaxTimeLengthCount = checkMaxTimeLengthCount.map((itmInn) => {
      let kit = []
      let test = itmInn?.times.map((innerKit) => {
        if (_.isEmpty(innerKit) === false) {
          kit.push(innerKit)
        }
      })
      return kit.length
    })
    checkMaxTimeLengthCount =
      checkMaxTimeLengthCount.length > 0
        ? Math.max.apply(null, checkMaxTimeLengthCount)
        : 0
    return checkMaxTimeLengthCount
  }

  let lcGetMaxLength = getMaxLength()

  return (
    <div>
      <div>
        <div className="cls-oph-itm-day">
          <div
            style={{
              display: "flex",
            }}
            className="cls-oph-itm-day-kit"
          >
            <div style={{ marginRight: 10 }}>{"Days"}</div>
          </div>
          <div className="cls-oph-group-time">
            {business_hrs &&
              business_hrs !== undefined &&
              business_hrs !== "" && (
                <FormHelperText style={{ color: "red" }}>
                  {business_hrs}
                </FormHelperText>
              )}
            {[...Array(lcGetMaxLength)].map((itmkits) => {
              return (
                <div className="cls-oph-itm-time">
                  <div
                    style={{
                      width: "calc(100% - 50px)",
                      height: 40,
                      background: "#F3F7FB",
                      borderRadius: 4,
                      padding: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // gridColumn: `1 / span ${checkMaxTimeLengthCount}`,
                    }}
                  >
                    <div>{`Open Time`}</div>
                    <div>{`Close Time`}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
