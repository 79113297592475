import { makeStyles } from "@material-ui/core/styles"
import { spacing } from "@material-ui/system"
import styled from "styled-components/macro"
import { TextField as MuiTextField } from "@material-ui/core"

export const FormMainWrapper = styled.form`
  @media (min-width: 1024px) and (max-width: 1540px) {
    .cls-location-name-wrapper {
      border: none;
    }
    .MuiGrid-grid-lg-3 {
      max-width: 25% !important;
      flex-basis: 50% !important;
    }
  }
`
export const TextField = styled(MuiTextField)(spacing)

export const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap" },
  margin: { margin: theme.spacing(1) },
  withoutLabel: { marginTop: theme.spacing(3) },
  phonefield: {
    "& .react-tel-input": {
      width: "250px",
    },
  },
  textField: {
    marginBottom: "5px",
    marginLeft: "15px",
    width: "90%",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
  },
  textFieldLeft45: {
    "& .MuiInputBase-input": {
      paddingLeft: "45px",
    },
  },
  text: {
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  text2: {
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "230px",
    marginBottom: "10px",
  },
  text1: {
    marginBottom: "20px",
    paddingLeft: "0px",
    marginLeft: "10px",
  },
  textemail: {
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "230px",
  },
  textHeader: {
    marginLeft: "10px",
    marginBottom: "14px",
    fontWeight: "500",
    fontSize: "16px",
  },
  textHeader1: {
    marginBottom: "14px",
    paddingLeft: "20px",
    marginLeft: "0px",
    fontWeight: "600",
  },

  textCity: {
    width: "325px",
    paddingLeft: "20px",
  },
  textCode: {
    paddingLeft: "20px",
  },

  textHeader2: {
    marginLeft: "0px",
    marginBottom: "18px",
    fontWeight: "600",
    paddingLeft: "20px",
  },
  icon: {
    marginRight: "18px",
    color: "var(--primaryColor)",
    marginBottom: "-5px",
    fontSize: "30px",
    "@media(max-width: 1120px)": {
      marginRight: "4px",
      width: "14px",
    },
  },
  actionIcons: {
    color: "rgba(158, 158, 158, 1)",
    fontSize: "22px",
  },

  header2: {
    marginBottom: "15px",
    marginLeft: "10px",
    fontWeight: "800",
  },
  divider: {
    padding: "0.5em",
    minHeight: "80px",
    "@media(min-width: 600px)": {
      borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
    },
  },
  noDivider: {
    padding: "0.5em",
    minHeight: "80px",
  },
  divPadding: {
    padding: "2px",
  },
  smallTextInput: {
    width: "230px !important",
    height: "36px",
    marginBottom: "25px",
    marginRight: "18px",
  },
  optionalFieldText: {
    color: "#9E9E9E",
    fontSize: "12px",
    fontWeight: "400",
    paddingLeft: "5px",
    marginTop: "5px",
  },
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },

  adornmentBackground: {
    backgroundColor: "lightgrey",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "0px !important",

    position: "absolute",

    height: "100%",
    maxHeight: "unset",
    paddingLeft: "0px",

    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px !important",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px !important",
    },
  },

  adornmentStart: {
    left: 0,
    top: 0,
  },

  adornmentEnd: {
    right: 0,
    top: 0,
  },

  dottedDivider: {
    borderBottom: "none",
    marginTop: "15px",
    marginBottom: "15px",
    width: "100%",
    backgroundImage: "linear-gradient(to right, #808080 50%, transparent 50%)",
    backgroundSize: "20px 1px",
    backgroundRepeat: "repeat-x",
  },
}))
