import React from "react"
import { Checkbox } from "@material-ui/core" // Note: Checkbox is not used in this component.
import { filter, matches } from "lodash"

function CustomText({ dataItem, value, filterData, renderText }) {
  const { _id } = dataItem

  // Default values if no matching items are found
  const defaultFalseVal = "Custom"
  const defaultTrueVal = "Default"

  // Extract tab values based on the filterData
  const renderTextFalseVal =
    (filterData && filter(filterData, matches({ value: "false" }))?.[0]?.tab) ||
    defaultFalseVal
  let renderTextTrueVal =
    (filterData && filter(filterData, matches({ value: "true" }))?.[0]?.tab) ||
    defaultTrueVal

  renderTextTrueVal = (filterData && renderTextTrueVal) || renderText

  return (
    <td>
      {_id === "new" || !renderText ? (
        <span style={{ color: "#afafaf" }}>{renderTextFalseVal}</span>
      ) : (
        <span>{renderTextTrueVal}</span>
      )}
    </td>
  )
}

export default CustomText
