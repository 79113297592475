import axios from "axios"
import _ from "lodash"
import {
  API_URL,
  API_URL_STORES,
  API_URL_CUSTOMERS,
  API_URL_PRICES,
  API_URL_CUSTOMERS_NO_PROXY,
  API_URL_INVENTORY,
  API_URL_ROLES,
  API_URL_LOC_HIERARCHY,
  API_URL_PRODUCT,
  API_URL_CUSTOMERS_COMMON,
} from "../../utils/url"

import { axiosConfig } from "../apiHeaderService"
import UserService from "../UserService"
import jwt_decode from "jwt-decode"

const { location = {} } = window || {}

const headerAxios = axios.create()

export const headerAxiosziptastic = axios.create()

headerAxios.interceptors.request.use(
  async function (config) {
    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          if (!config.headers["X-Tenant-ID"]) {
            let tenantId =
              localStorage.getItem("tenantId") === null
                ? ""
                : localStorage.getItem("tenantId")
            config.headers["X-Tenant-ID"] = tenantId
            config.headers["X-KC-Realm-ID"] = localStorage.getItem(
              "X-KC-Realm-ID"
            )
            config.headers["X-KC-Host"] = localStorage.getItem("X-KC-Host")
          }

          config.headers["Authorization"] = "Bearer " + mydataToken
          return Promise.resolve(config)
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        if (!config.headers["X-Tenant-ID"]) {
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          config.headers["X-Tenant-ID"] = tenantId
          config.headers["X-KC-Realm-ID"] = localStorage.getItem(
            "X-KC-Realm-ID"
          )
          config.headers["X-KC-Host"] = localStorage.getItem("X-KC-Host")
        }
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
        return Promise.resolve(config)
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)

headerAxiosziptastic.interceptors.request.use(
  async function (config) {
    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          config.headers["x-Key"] = "b08143470b1d74904b91b90446dc5fe14543c87b"
          return Promise.resolve(config)
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        config.headers["x-Key"] = "b08143470b1d74904b91b90446dc5fe14543c87b"
        return Promise.resolve(config)
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)

let apiEndpoint = process.env.REACT_APP_API_ENDPOINT
apiEndpoint = location.origin.includes("localhost")
  ? apiEndpoint
  : "https://api" + location?.host + "/portal"

const API_BASE_URL = apiEndpoint
const KC_API_BASE_URL = apiEndpoint

export { headerAxios, API_BASE_URL, KC_API_BASE_URL }

export const doGetFetchAPI = (props, isLocal = false) => {
  const { END_POINTS = "", prefixUrl = "" } = props || {}

  // let apiURL = prefixUrl.length > 0 ? prefixUrl : API_URL

  const API_BASE_URLs = API_BASE_URL.replace(":3013", "")
  let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl

  let convert = apiURL + END_POINTS
  if (isLocal) {
    convert = convert.replace(
      "https://apidevdm.digitmart.ai/portal",
      "http://192.168.11.156:8004"
    )
    convert = convert.replace("gondola/", "")
  }
  console.log("apiURL", convert)
  return headerAxios.get(convert)
}

// just for testing new dashboard api integration function (84-93)
// export const localTestdoGetFetchAPI = (props) => {
// const { END_POINTS = "", prefixUrl = "" } = props || {}

// let apiURL = prefixUrl.length > 0 ? prefixUrl : API_URL

// const API_BASE_URLs = API_BASE_URL.replace(":3013", "")
// let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl

// return headerAxios.get("http://localhost" + END_POINTS)
// }
// just for testing new dashboard api integration function (84-93)

// export const localTestdoPostFetchAPI = (props) => {
//   const { END_POINTS = "", prefixUrl = "", body = {} } = props || {}
//   // let apiURL = prefixUrl.length > 0 ? prefixUrl : API_URL
//   const API_BASE_URLs = API_BASE_URL.replace(":3013", "")
//   let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl
//   return headerAxios.post("http://localhost" + END_POINTS, body)
// }

export const doPostFetchAPI = (props, config = {}, isLocal = false) => {
  const { END_POINTS = "", prefixUrl = "", body = {} } = props || {}
  // let apiURL = prefixUrl.length > 0 ? prefixUrl : API_URL
  const API_BASE_URLs = API_BASE_URL.replace(":3013", "")
  let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl

  let convert = apiURL + END_POINTS
  if (isLocal) {
    convert = convert.replace(
      "https://apidevdm.digitmart.ai/portal",
      "http://192.168.11.156:8004"
    )
    convert = convert.replace("gondola/", "")
  }

  return headerAxios.post(convert, body, config)
}
export const doPutFetchAPI = (props, config = {}, isLocal = false) => {
  const { END_POINTS = "", prefixUrl = "", body = {} } = props || {}
  // let apiURL = prefixUrl.length > 0 ? prefixUrl : API_URL
  const API_BASE_URLs = API_BASE_URL.replace(":3013", "")
  let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl
  let convert = apiURL + END_POINTS
  if (isLocal) {
    convert = convert.replace(
      "https://apidevdm.digitmart.ai/portal",
      "http://192.168.11.156:8004"
    )
    convert = convert.replace("gondola/", "")
  }
  return headerAxios.put(convert, body, config)
}
export const getCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_URL + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const getCommonDatabody = async (
  END_POINTS,
  bodyData = {},
  config = {}
) => {
  try {
    let response = await headerAxios.get(API_URL + END_POINTS, bodyData, config)
    return response
  } catch (error) {
    return error
  }
}

export const fetchCommonData = async (
  END_POINTS,
  bodyData = {},
  config = {}
) => {
  try {
    let response = await headerAxios.post(
      API_URL + END_POINTS,
      bodyData,
      config
    )
    return response
  } catch (error) {
    return error
  }
}

export const deleteRecords = async (END_POINTS, config = {}) => {
  try {
    let response = await headerAxios.delete(API_URL + END_POINTS, config)
    return response
  } catch (error) {
    return error
  }
}

export const fetchCommonDataCustom = async (
  END_POINTS,
  bodyData = {},
  config = {}
) => {
  try {
    let response = await headerAxios.post(END_POINTS, bodyData, config)
    return response
  } catch (error) {
    return error
  }
}
export const putCommonDataCustom = async (
  END_POINTS,
  bodyData = {},
  config = {}
) => {
  try {
    let response = await headerAxios.put(END_POINTS, bodyData, config)
    return response
  } catch (error) {
    return error
  }
}
export const getCommonDataCustom = async (
  END_POINTS,
  bodyData = {},
  config = {}
) => {
  try {
    let response = await headerAxios.get(END_POINTS, bodyData, config)
    return response
  } catch (error) {
    return error
  }
}
export const deleteCommonDataCustom = async (
  END_POINTS,
  bodyData = {},
  config = {}
) => {
  try {
    let response = await headerAxios.delete(END_POINTS, bodyData, config)
    return response
  } catch (error) {
    return error
  }
}
export const fetchStoreDashBoardData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_URL + END_POINTS, bodyData)

    return response
  } catch (error) {
    return error
  }
}
export const fetchStoreDashTenantData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_URL + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}

// export const fetchRoleData = async (END_POINTS, bodyData = {}) => {
//   try {
//     let response = await axios.post(API_URL_ROLES + END_POINTS, bodyData)
//     return response
//   } catch (error) {
//     return error
//   }
// }
export const fetchCommonRoleData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_URL_ROLES + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}

export const getCommonRoleData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_URL_ROLES + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const putCommonRoleData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.put(API_URL_ROLES + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}

export const fetchStoreCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_URL_STORES + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}
export const fetchStoreTaxData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_URL_STORES + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}
export const putStoreCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.put(API_URL_STORES + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}

export const getStoreCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_URL_STORES + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const fetchCustomerCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(
      API_URL_CUSTOMERS_COMMON + END_POINTS,
      bodyData
    )
    return response
  } catch (error) {
    return error
  }
}

export const getCustomerCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_URL_CUSTOMERS_COMMON + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const putCustomerCommonData = async (END_POINTS, bodyData, id = {}) => {
  try {
    let response = await headerAxios.put(
      API_URL_CUSTOMERS_COMMON + END_POINTS + "/" + id,
      bodyData
    )
    return response
  } catch (error) {
    return error
  }
}

export const postProductCommonData = async (END_POINTS, body = {}) => {
  try {
    let response = await headerAxios.post(API_URL_PRODUCT + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const getProductCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_URL_PRODUCT + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const patchProductCommonData = async (END_POINTS, body = {}) => {
  try {
    let response = await headerAxios.patch(API_URL_PRODUCT + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const fetchInventoryCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(
      API_URL_INVENTORY + END_POINTS,
      bodyData
    )
    return response
  } catch (error) {
    return error
  }
}

export const putInventoryCommonData = async (END_POINTS, bodyData) => {
  try {
    let response = await headerAxios.put(
      API_URL_INVENTORY + END_POINTS,
      bodyData
    )
    return response
  } catch (error) {
    return error
  }
}

export const getAllCustomers = async (END_POINTS, page, limit, filter) => {
  try {
    const storesLink =
      filter === ""
        ? API_URL_CUSTOMERS + END_POINTS + "?page=" + page + "&limit=" + limit
        : API_URL_CUSTOMERS +
        END_POINTS +
        "?" +
        filter +
        "&page=" +
        page +
        "&limit=" +
        limit
    let response = await headerAxios.get(storesLink)

    return response
  } catch (error) {
    return { status: "failed" }
  }
}

export const getAllInventory = async (END_POINTS, page, limit = 10, filter) => {
  try {
    const inventoryLink =
      filter === ""
        ? API_URL_INVENTORY + END_POINTS + "?page=" + page + "&limit=" + limit
        : API_URL_INVENTORY +
        END_POINTS +
        "/?" +
        filter +
        "&page=" +
        page +
        "&limit=" +
        limit
    let response = await headerAxios.get(inventoryLink)
    return response
  } catch (error) {
    return { status: "failed" }
  }
}
export const getAllProducts = async (END_POINTS, page, limit, filter) => {
  try {
    const productLink =
      filter === ""
        ? API_URL_PRODUCT + END_POINTS + "?page=" + page + "&limit=" + limit
        : API_URL_PRODUCT +
        END_POINTS +
        "?" +
        filter +
        "&page" +
        page +
        "&limit" +
        limit
    let response = await headerAxios.get(productLink)
    return response
  } catch (error) {
    return { status: "failed" }
  }
}

export const getAllPrices = async (END_POINTS, page, limit, filter) => {
  try {
    const storesLink =
      filter === ""
        ? API_URL_PRICES + END_POINTS + "?page=" + page + "&limit=" + limit
        : API_URL_PRICES +
        END_POINTS +
        "?" +
        filter +
        "&page=" +
        page +
        "&limit=" +
        limit
    let response = await headerAxios.get(storesLink)
    return response
  } catch (error) {
    return { status: "failed" }
  }
}
export const getElasticSearch = async (END_POINTS = "") => {
  try {
    const storesLink = API_URL_PRICES + END_POINTS
    let response = await headerAxios.get(storesLink)
    return response
  } catch (error) {
    return { status: "failed" }
  }
}

export const addNewAddress = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_URL_CUSTOMERS + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const editAddress = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_URL_CUSTOMERS + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const postCommonDataConfig = async (
  END_POINTS,
  body,
  realmId,
  tenantId
) => {
  try {
    let response = await headerAxios.post(API_URL + END_POINTS, body, {
      headers: {
        "X-KC-Realm-ID": realmId,
        "X-Tenant-ID": tenantId,
        "X-KC-Host": localStorage.getItem("X-KC-Host"),
      },
    })
    return response
  } catch (error) {
    return error
  }
}

export const postCommonDataConfigCustom = async (
  END_POINTS,
  body,
  realmId,
  tenantId
) => {
  try {
    let response = await headerAxios.post(API_URL + END_POINTS, body, {
      headers: {
        "Realm-ID": realmId,
        "Tenant-ID": tenantId,
        Host: localStorage.getItem("X-KC-Host"),
      },
    })
    return response
  } catch (error) {
    return error
  }
}

export const postCommonData = async (END_POINTS, body, config = {}) => {
  try {
    let response = await headerAxios.post(API_URL + END_POINTS, body, config)
    return response
  } catch (error) {
    return error
  }
}

export const putCommonData = async (END_POINTS, body, config = {}) => {
  try {
    let response = await headerAxios.put(API_URL + END_POINTS, body, config)
    return response
  } catch (error) {
    return error
  }
}

export const updateCommonData = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.patch(API_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const deleteCommonData = async (END_POINTS, config = {}) => {
  try {
    let response = await headerAxios.delete(API_URL + END_POINTS, config)
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleCommonData = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(API_URL + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}
export const getAllLocation = async (page, limit, filter) => {
  try {
    const storesLink =
      filter === ""
        ? `${API_BASE_URL}/api/stores/stores?page=` + page + "&limit=" + limit
        : `${API_BASE_URL}/api/stores/stores?` +
        filter +
        "&page=" +
        page +
        "&limit=" +
        limit

    let response = await headerAxios.get(storesLink)
    return response
  } catch (error) {
    return error
  }
}

export const getAllLocationGroups = async (page, limit, filter) => {
  try {
    // const storesLink =
    //   filter === ""
    //     ? "http://ec2-35-153-173-81.compute-1.amazonaws.com:3004/api/stores/stores?page=" +
    //       page +
    //       "&limit=" +
    //       limit
    //     : "http://ec2-35-153-173-81.compute-1.amazonaws.com:3004/api/stores/stores?" +
    //       filter +
    //       "&page=" +
    //       page +
    //       "&limit=" +
    //       limit

    let response = await headerAxios.get(
      `${API_BASE_URL}/api/stores/getallgroups`
    )
    return response
  } catch (error) {
    return error
  }
}

export const getAllStates = async () => {
  try {
    const statesLink = `${API_BASE_URL}/api/stores/getAllRegions`
    let response = await headerAxios.get(statesLink)
    return response
  } catch (error) {
    return error
  }
}

export const postCommonDataURL = async (URL_END_POINTS, body) => {
  try {
    let response = await headerAxios.post(URL_END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const getCommonDataUrlOnId = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const updateCommonDataURLOnId = async (URL_END_POINTS, body) => {
  try {
    let response = await headerAxios.patch(URL_END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const deleteCommonDataUrlOnId = async (END_POINTS) => {
  try {
    let response = await headerAxios.delete(END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleStores = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(API_URL_STORES + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleProducts = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(API_URL_PRODUCT + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleCustomers = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(
      API_URL_CUSTOMERS_NO_PROXY + END_POINTS,
      {
        data: body,
      }
    )
    return response
  } catch (error) {
    return error
  }
}
export const deleteMultipleInventories = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(API_URL_INVENTORY + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}
export const deleteMultiplePrice = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}

export const createNewInventory = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_URL_INVENTORY + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}
export const getCommonInvontery = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(END_POINTS)
    return response
  } catch (error) {
    return error
  }
}
export const createNewPrice = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_URL_PRICES + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const getLocationHierarchy = async () => {
  try {
    let response = await headerAxios.get(API_URL_LOC_HIERARCHY)
    return response
  } catch (error) {
    return error
  }
}

export const getAllCities = async () => {
  try {
    let response = await headerAxios.get(
      `${API_BASE_URL}/api/stores/getAllCities`
    )
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleAttibutes = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(API_BASE_URL + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}

export const bulkUploadProducts = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_BASE_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const fetchZipcodeDetails = async (zipcode, countryCode = "US") => {
  let url = "https://zip.getziptastic.com"
  let urlVersion = "v3"
  try {
    let response = await headerAxiosziptastic.get(
      `${url}/${urlVersion}/${countryCode}/${zipcode}`
    )
    return response
  } catch (error) {
    return error
  }
}
