import React, { useEffect } from "react"
import styled from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  Typography as MuiTypography,
  FormHelperText,
  IconButton,
} from "@material-ui/core"
import { CalendarIcon } from "@iconsGallery"
import { useTranslation } from "react-i18next"
import { spacing } from "@material-ui/system"
import "./styles.css"
import Divider from "@material-ui/core/Divider"
import * as Utils from "@utils"
import * as Features from "@features"
import * as Adm from "@adm"
import moment from "moment"
import { useState } from "react"

const MainWrapper = styled(Grid)`
  .cls-open-calender {
    display: flex;
    align-items: center;
  }
  .cls-timezone {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  .cls-close-calender {
    display: flex;
    align-items: center;
    padding-top: 0px;

    .cls-calender-date-native {
      margin-top: 0px;
    }
  }

  .cls-open-date-label {
    margin-right: 68px;
  }
  .cls-close-date-label {
    margin-right: 55px;
  }
`
const Typography = styled(MuiTypography)(spacing)
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 150,
  },
  contolChildGridSecond: {
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
  },
  contolChildGridFirst: (width) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    marginTop: 15,
    paddingLeft: 20 + width,
    fontSize: "13px",
    marginRight: "15px",
  }),

  contolChildGridFirstdivSecSpan: (width) => ({
    paddingLeft: width,
    marginTop: "10px",
    paddingTop: "10px",
  }),
  contolChildGridSecdivFirstSpan: (width) => ({
    paddingLeft: 10 + width,
    fontSize: "14px",
  }),
  textBoxHeader: {
    fontWeight: "bold",
    paddingLeft: "4px",
    marginBottom: "0px",
    paddingTop: "5px",
  },
  autoCompleteHeight: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
      width: "100%",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
  },
})



const OpenDateMain = ({
  isWriteAllowed = false,
  selectedOpenDat,
  selectedCloseDat,
  setAllowPrompt = () => { },
  locationTimezone = "",
  editMode,
  setEditMode,
  saveHandler,
  slashHandler,
  handleDateChange,
  handleTimeZoneChange,
  loading,
  isAnyChanges,
  setAnyChanges,
  defaultTimeZone,
  isTablet = false,
  timeZoneList = [],
  values = {},
  errors = {},
  touched = {},
  handleBlur = () => '',
  onGlobalUpdate,
  title = "STORE DATE AND INFORMATION",
}) => {
  const { useWindowSize } = Utils || {}
  const [height, width] = useWindowSize()
  const classes = useStyles(width)
  const { t } = useTranslation()

  const [selectedOpenDate, setSelectedOpenDate] = React.useState(
    selectedOpenDat
  )
  const [selectedCloseDate, setSelectedCloseDate] = React.useState(
    selectedCloseDat
  )
  const [timeZoneOpen, setTimeZoneOpen] = React.useState(false)
  const closeDateValidation =
    selectedCloseDate < selectedOpenDate && selectedCloseDate !== ""

  useEffect(() => {
    // console.log("selectedOpenDat",selectedOpenDat)
    setSelectedOpenDate(selectedOpenDat)
    setSelectedCloseDate(selectedCloseDat)
    return () => { }
  }, [selectedCloseDat, selectedOpenDat])

  const getActualDate = (date) => {
    let dateArray = date.split("-")
    let dat = parseInt(dateArray[2])
    let mon = parseInt(dateArray[1]) - 1
    let yrs = parseInt(dateArray[0])
    let currentDate = new Date(yrs, mon, dat)
    let sendDate = moment(currentDate).format("yyyy-MM-DD")
    return sendDate
  }

  const getViewedData = (date) => {
    if (date) {
      let dateArray = date.split("-")
      let dat = parseInt(dateArray[2])
      let mon = parseInt(dateArray[1]) - 1
      let yrs = parseInt(dateArray[0])
      let currentDate = new Date(yrs, mon, dat)
      let changeDate = new Date(currentDate).toLocaleDateString("en-GB", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
      return changeDate
    }
    else {
      return ""
    }
  }

  return (
    <MainWrapper item lg={12} md={12} sm={12} xs={12}>
      <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="center"
        style={{
          justifyContent: "space-between",
          paddingLeft: "10px",
          height: "60px",
        }}
      >
        <Grid item>
          <Typography
            variant="h5"
            style={{
              fontWeight: "700",
              fontSize: "15px",
            }}
          >
            <IconButton>
              <CalendarIcon />
            </IconButton>
            {t(title)}
          </Typography>
        </Grid>
      </Grid>
      <div timeout="auto">
        {locationTimezone === "" && !editMode ? (
          <Features.NoRecordFound />
        ) : (
          <>
            {/* Start */}
            <Grid
              container
              direction="row"
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              xl={12}
            >
              <Grid
                item
                lg={4}
                md={6}
                sm={6}
                xs={12}
                className={classes.divider}
                style={{ paddingLeft: "30px", marginTop: "0px" }}
              >
                <Typography className={classes.textBoxHeader}>
                  Time Zone
                  {editMode ? <span style={{ color: "red" }}>*</span> : ""}
                </Typography>
                {!editMode ? (
                  <Typography style={{ fontWeight: 500, margin: '7px 4px' }}>
                    {locationTimezone}
                  </Typography>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    <Adm.DropdownFilter
                      name="locationTimezone"
                      list={timeZoneList || []}
                      selectedValue={locationTimezone}
                      onChangeEvent={(e, details) => {
                        handleTimeZoneChange(e, details)
                        onGlobalUpdate()
                      }}
                      onBlur={handleBlur}
                      popupOpen={timeZoneOpen}
                      setPopupOpen={setTimeZoneOpen}
                      disableClearable={true}
                      classNameTextField={classes.autoCompleteHeight}
                      style={{ width: "310px" }}
                      placeholder={"Select Time Zone"}
                      touchedControl={touched?.locationTimezone}
                      errorControl={errors?.locationTimezone}
                    />
                  </div>
                )}
              </Grid>

              <Grid
                item
                lg={4}
                md={6}
                sm={6}
                xs={12}
                className={`${isTablet ? classes.noDivider : classes.divider}`}
                style={{ paddingLeft: "30px" }}
              >
                <Typography className={classes.textBoxHeader}>
                  Open Date{" "}
                  {editMode ? <span style={{ color: "red" }}>*</span> : ""}
                </Typography>
                {!editMode ? (
                  <Typography style={{ fontWeight: 500, margin: '7px 4px' }}>
                    {getViewedData(selectedOpenDate)}
                  </Typography>
                ) : (
                  <div style={{ marginTop: "-5px" }}>
                    <Adm.Calender
                      fullWidth
                      style={{ width: "310px" }}
                      isTimeEnable={false}
                      allowFutureDate={true}
                      onBlur={handleBlur}
                      date={
                        selectedOpenDate != null
                          ? getActualDate(selectedOpenDate)
                          : null
                      }
                      error={
                        touched.openDate && errors.openDate && errors?.openDate !== ""
                          ? "Select Open Date"
                          : ""
                      }
                      onUpdateCalender={({ date }) => {
                        handleDateChange(date, "openDate")
                        onGlobalUpdate()
                      }}
                    />
                  </div>
                )}
              </Grid>

              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                className={classes.divider}
                style={{ paddingLeft: "30px" }}
              >
                <Typography variant="h5" className={classes.textBoxHeader}>
                  Close Date
                </Typography>
                {!editMode ? (
                  <Typography style={{ fontWeight: 500, margin: '7px 4px' }}>
                    {selectedCloseDate ? getViewedData(selectedCloseDate) : "-"}
                  </Typography>
                ) : (
                  <div style={{ marginTop: "0px" }}>
                    <Adm.Calender
                      fullWidth
                      style={{ width: "310px" }}
                      isTimeEnable={false}
                      allowFutureDate={true}
                      date={
                        selectedCloseDate !== null
                          ? getActualDate(selectedCloseDate)
                          : ""
                      }
                      error={
                        selectedCloseDate < selectedOpenDate &&
                          selectedCloseDate !== ""
                          ? "Select valid Close Date"
                          : ""
                      }
                      onUpdateCalender={({ date }) => {
                        handleDateChange(date, "closeDate")
                        onGlobalUpdate()
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            {/* End */}
          </>
        )}
      </div>
    </MainWrapper>
  )
}

export { OpenDateMain }
export default OpenDateMain
