import React, { useState, useEffect } from "react"
import { StyledDOMSlider, StyledWrapper } from "./styles"

function RangeSlider(props = {}) {
  const {
    defaultMinValue = 1,
    defaultMaxValue = 25,
    defaultStepMoveValue = 1,
    defaultValue = 0,
    onUpdate = () => "",
    defaultSuffixTextMove = "days",
  } = props || {}

  const [value, setValue] = useState(1)



  useEffect(() => {
    let val;
    if (defaultValue > 0) {
      val = defaultValue
    } else {
      val = defaultMaxValue
    }

    setValue(val)
    onUpdate({ value: val, type: 'initial' })
  }, [])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (defaultValue === 0) {
      setValue(defaultMaxValue)
      onUpdate({ value: defaultMaxValue, type: 'initial' })
    }
  }, [defaultMaxValue])


  const customRangeProps = {
    key: defaultMinValue,
    valueLabelDisplay: "auto",
    value: value,
    min: defaultMinValue,
    max: defaultMaxValue,
    step: defaultStepMoveValue,
    marks: [
      { value: defaultMinValue, label: `${defaultMinValue}` },
      { value: defaultMaxValue, label: `${defaultMaxValue}` },
    ],
    onChange: (event, newValue) => {
      setValue(newValue)
      onUpdate({ value: newValue, type: 'current' })
    },
    valueLabelFormat: (value) => `${value} ${defaultSuffixTextMove}`,
  }
  return (
    <StyledWrapper>
      {defaultMinValue === defaultMaxValue ? (
        <div style={{ position: "relative" }}>
          <div className="cls-static-suffix">
            {defaultMinValue + " " + defaultSuffixTextMove}
          </div>
          <div className="cls-static-thumb" />
          <div className="cls-static-suffix2">{defaultMinValue}</div>
        </div>
      ) : (
        <StyledDOMSlider {...customRangeProps} />
      )}
    </StyledWrapper>
  )
}

export { RangeSlider }
export default RangeSlider
