import { combineReducers } from "redux"

import themeReducer from "./themeReducer"
import authReducer from "./authReducer"
import locationReducer from "./locationReducer"
import addLocationReducer from "./addLocationReducer"
import stepperReducer from "./stepperReducer"
import countryReducer from "./countryReducer"
import treeHierarchyReducer from "./treeHierarchyReducer"
import addCustomerReducer from "./addCustomerReducer"
import adminReducer from "../../reduxSagas/admin/reducer"
import productsReducer from '../../services/masterData/products/reducer';
import locationHierarchyReducer from '../../services/masterData/locations/reducer'
import loginstateReducer from "./loginstateReducer"
import addPosReducer from "./addPosReducer"
import deviceMgmtReducer from "./deviceMgmtReducer"
import profileUpdateReducer from "./profileUpdateReducer"

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  locationReducer,
  addLocationReducer,
  loginstateReducer,
  stepperReducer,
  countryReducer,
  treeHierarchyReducer,
  addCustomerReducer,
  adminReducer,
  productsReducer,
  locationHierarchyReducer,
  addPosReducer,
  deviceMgmtReducer,
  profileUpdateReducer
})
