import React, { useEffect, useState } from "react"
import { withTheme } from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import {
  Divider,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  TextField,
  InputLabel,
  InputAdornment,
} from "@material-ui/core"
import * as ReduxHelper from "@reduxHelper"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import _ from "lodash"
import * as Adm from "@adm"
import { react } from "@babel/types"
import { async } from "q"

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    "& .MuiFormHelperText-contained": {
      margin: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
  locSizeWidth: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
    "& .MuiOutlinedInput-root": { width: "100%", height: "36px" },
    "& .MuiFormHelperText-contained": {
      margin: "0px",
    },
  },
  mulSymbol: {
    fontSize: "18px",
    fontWeight: 400,
    padding: "5px",
    alignSelf: "flex-start",
  },
  errorMsgDiv: { width: "160px" },
  disabledTextInput: {
    "& input": {
      backgroundColor: "#f6f6f6",
    },
  },
  textEmail: {
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "230px",
  },

  rangeSliderWrapper: {
    paddingLeft: "15px",
  },
  autoCompleteHeight: {
    paddingLeft: "25px",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
      width: "100%",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
  },
  dottedDivider: {
    borderBottom: "none", // Remove the default border
    marginTop: "15px",
    marginBottom: "15px",
    width: "100%", // Ensure it spans the full width of the container
    backgroundImage: "linear-gradient(to right, #808080 50%, transparent 50%)", // Creates a dashed effect
    backgroundSize: "20px 1px", // Size of each dash and space
    backgroundRepeat: "repeat-x", // Repeat the background horizontally
  },
  rngCss: {
    paddingLeft: "30px",
  },
  textField: {
    marginBottom: "5px",
    marginLeft: "15px",
    width: "90%",
    paddingLeft: "20px",

    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
  },
  textHeader1: {
    marginBottom: "14px",
    paddingLeft: "30px",
    marginLeft: "0px",
    fontWeight: "600",
    marginTop: "0px",
  },

  gstNum: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))

function LocationTreeComponent(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    parentUseStyles = {},
    setFieldValue = () => { },
    errors = {},
    touched = {},
    isLegacy = true,
    edit,
    locationSize = {},
    locationType,
    locations,
    locationTypeError,
    locationSizeError,
    setLocationSize,
    setLocationType,
    setAnyChanges,
    isDetailView = false,
    setAllowPrompt = () => { },
    storeList = [],
    setStoreType = () => { },
    storeType = {},
    storeTypeError,
    SDCConfigured,
    isDesktop = false,
    isTablet = false,
    paymentGatewayList = [],
    paymentType = {},
    setPaymentType = () => { },
    paymentTypeError,
    accountList = [],
    accountType = "",
    setAccountType = () => { },
    accountTypeError,
    locationId = "",
    values = {},
    storeId = "",
    handleBlur = () => "",
  } = props || {}


  const classes = useStyles()
  const parentClasses = parentUseStyles()

  const [paymentOpen, setPaymentOpen] = useState(false)
  const [paymentModeOpen, setPaymentModeOpen] = useState(false)
  const [getTransactionWindowRange, setTransactionWindowRange] = useState({})

  const getSelectedValue = (list, val, key = "id") =>
    _.find(list, (o) => _.get(o, key) === _.get(val, key, "-"))

  function onlyDotsAndNumbers(txt, event) {
    var charCode = event.which ? event.which : event.keyCode
    if (charCode === 46) {
      if (txt.value.indexOf(".") < 0) return true
      else return false
    }

    if (txt.value.indexOf(".") > 0) {
      var txtlen = txt.value.length
      var dotpos = txt.value.indexOf(".")
      if (txtlen - dotpos > 2) return false
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false

    return true
  }

  const handleKeyPress = (e, type) => {
    if (type === "number") {
      if (e.which === 32) {
        e.preventDefault()
      }
    } else {
      if (e.which === 32 && e.target.value === "") {
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    setFieldValue(
      "payment_provider",
      values.paymentAccountName.payment_provider
    )
    setFieldValue("paymentMode", values.paymentAccountName.paymentMode)
  }, [])

  useEffect(() => {
    if (edit) {
      let tempAccountObj = _.filter(accountList, function (o) {
        return o?.name === values?.paymentAccountName?.name
      })
      setTransactionWindowRange(
        (tempAccountObj && tempAccountObj[0]?.transaction_period) || {}
      )
    }
  }, [edit])

  let isAccountNameValueAvailable = values?.paymentAccountName?.name || ""

  return (
    <>
      <Grid
        container
        direction="row"
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        xl={12}
        style={{ padding: "10px 5px", margin: "0px", marginBottom: "20px" }}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          className={`${isTablet ? classes.noDivider : classes.divider}`}
        >
          <Typography variant="h5" className={classes.textHeader1}>
            Account Name{" "}
            <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
          </Typography>

          {edit ? (
            <Adm.DropdownFilter
              name={"paymentAccountName"}
              list={accountList || []}
              defaultValue={values?.paymentAccountName?.name}
              selectedValue={values?.paymentAccountName?.name}
              onChangeEvent={(e, obj) => {
                setAnyChanges(true)
                setFieldValue("paymentAccountName", obj)
                setFieldValue(
                  "preAuthAmountRequired",
                  obj?.is_pre_auth_available
                )
                if (obj?.is_pre_auth_available) {
                  setFieldValue("preAuthAmount", "")
                }
                if (obj.hasOwnProperty("transaction_period")) {
                  setTransactionWindowRange(obj?.transaction_period)
                  setFieldValue(
                    "order_expiry_in_days",
                    obj?.transaction_period?.allowed_order_expiry_in_days_max
                  )
                  setFieldValue(
                    "refund_window_in_days",
                    obj?.transaction_period?.allowed_order_expiry_in_days_max
                  )
                }
              }}
              onBlur={handleBlur}
              popupOpen={paymentModeOpen}
              setPopupOpen={setPaymentModeOpen}
              disableClearable={true}
              classNameTextField={classes.autoCompleteHeight}
              touchedControl={touched?.paymentAccountName}
              errorControl={errors?.paymentAccountName}
              placeholder={"Select Account Name"}
              style={{ width: "350px", paddingLeft: "0px" }}
            />
          ) : (
            <Typography
              variant="body1"
              style={{ padding: "19px 30px", fontWeight: 500 }}
            >
              {values?.paymentAccountName?.name}
            </Typography>
          )}
        </Grid>

        {values?.preAuthAmountRequired && (
          <>
            <Grid item lg={6} md={6} sm={6} xs={6} className={classes.divider}>
              <Typography variant="h5" className={classes.textHeader1}>
                PreAuth Amount{" "}
                <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
              </Typography>
              {edit ? (
                <>
                  <TextField
                    defaultValue={values.preAuthAmount}
                    value={values.preAuthAmount}
                    variant="outlined"
                    name="preAuthAmount"
                    autoComplete="off"
                    placeholder="PreAuth Amount"
                    onKeyPress={(e) => handleKeyPress(e, "text")}
                    error={Boolean(
                      touched.preAuthAmount && errors.preAuthAmount
                    )}
                    helperText={touched.preAuthAmount && errors.preAuthAmount}
                    onBlur={handleBlur}
                    onChange={(props) => {
                      if (values.preAuthAmount !== null) {
                        setAllowPrompt(true)
                      }
                      setFieldValue("preAuthAmount", props?.target?.value)
                      setAnyChanges(true)
                    }}
                    style={{ width: "350px", paddingLeft: "10px" }}
                    className={[
                      classes.textField,
                      parentClasses.textFieldLeft45,
                    ]}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={[
                            parentClasses.adornmentBackground,
                            parentClasses.adornmentStart,
                          ]}
                        >
                          {"$"}{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              ) : (
                <>
                  <Typography
                    className={classes.textEmail}
                    variant="body1"
                    style={{ padding: "19px 30px", fontWeight: 500 }}
                  >
                    {values?.preAuthAmount || "-"}
                  </Typography>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        container
        direction="row"
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        xl={12}
      >
        {isAccountNameValueAvailable.length > 0 && (
          <>
            <Grid item lg={6} md={8} sm={6} xs={12} className={classes.divider}>
              <Typography
                variant="h5"
                className={classes.textHeader1}
                style={{ paddingLeft: "34px" }}
              >
                Transaction Window{" "}
                <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
              </Typography>
              <Typography
                variant="h5"
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "35px",
                }}
              >
                In transaction how many days validity configurable plan
              </Typography>
              {edit ? (
                <div
                  className="rangeSliderWrapper"
                >
                  <Adm.RangeSlider
                    defaultMinValue={
                      getTransactionWindowRange?.allowed_order_expiry_in_days_min
                    }
                    defaultMaxValue={
                      getTransactionWindowRange?.allowed_order_expiry_in_days_max
                    }
                    defaultStepMoveValue={1}
                    defaultValue={values?.order_expiry_in_days}
                    onUpdate={({ value, type = false }) => {
                      setFieldValue("order_expiry_in_days", value)
                      if (value < values?.refund_window_in_days) {
                        setFieldValue("refund_window_in_days", value)
                      }
                      if (type === "current") {
                        setAnyChanges(true)
                      }
                    }}
                  />
                </div>
              ) : (
                <Typography
                  variant="body1"
                  style={{ padding: "19px 31px", fontWeight: 500 }}
                >
                  {values?.order_expiry_in_days} {" days"}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className={`${isTablet ? classes.noDivider : classes.divider}`}
            >
              <Typography variant="h5" className={classes.textHeader1}>
                Refund Window{" "}
                <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
              </Typography>

              <Typography
                variant="h5"
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "400",

                  paddingLeft: "30px",
                }}
              >
                In refund how many days validity configurable plan
              </Typography>

              {edit ? (
                <div
                  className="rangeSliderWrapper"
                >
                  <Adm.RangeSlider
                    defaultMinValue={
                      getTransactionWindowRange?.allowed_order_expiry_in_days_min
                    }
                    defaultMaxValue={values?.order_expiry_in_days}
                    defaultStepMoveValue={1}
                    defaultValue={values?.refund_window_in_days}
                    onUpdate={({ value, type = false }) => {
                      setFieldValue("refund_window_in_days", value)
                      if (type === "current") {
                        setAnyChanges(true)
                      }
                    }}
                  />
                </div>
              ) : (
                <Typography
                  variant="body1"
                  style={{ padding: "19px 25px", fontWeight: 500 }}
                >
                  {values?.refund_window_in_days} {" days"}
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Divider className={classes.dottedDivider} />
    </>
  )
}

const LocationTree = withTheme(LocationTreeComponent)
export { LocationTree }
export default LocationTree
