import { useState, useEffect } from "react"
import * as API from "./api"
import { getCityFromList } from "./deps"

function useZipCodeBox(props) {
  const {
    defaultZipCode = "",
    defaultMinText = 4,
    defaultMaxText = 9,

    onVerify = () => "",
    onChanged = () => "",
    onBlur = () => "",

    height = '43',
    width = '150',
    fontSize = '10.75',
    borderRadius = '15',

    name = 'zipcode',

    defaultIsRequired = false,

    defaultErrorMessage = "",
    defaultErrorFlag = false,
    defaultReadOnly = false,
    defaultButtonDisable = false,
    defaultCountryCode = 'US',

    ...restProps
  } = props || {}

  const [getZipCode, setZipCode] = useState("")
  const [getErrorMessage, setErrorMessage] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false);
  const [inputReadOnly, setInputReadOnly] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [clearButtonShow, setClearButtonShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [verifiedFlag, setVerifiedFlag] = useState(false);
  const [notValidFlag, setNotValidFlag] = useState(false);

  const [alreadyVerifiedZipcodes, setAlreadyVerifiedZipcodes] = useState([]);

  // global variables
  let globalZipCode;

  const changeZipCode = (value) => {
    setZipCode(value)
    globalZipCode = value
  }

  const changeErrorMessage = (msg) => {
    if (defaultErrorMessage) {
      setErrorMessage(defaultErrorMessage)
    } else {
      setErrorMessage(msg)
    }
  }

  const changeErrorFlag = (tof) => {
    if (defaultErrorFlag) {
      setErrorFlag(defaultErrorFlag);
    } else {
      setErrorFlag(tof);
    }
  }

  const changeInputReadOnly = (tof) => {
    if (defaultReadOnly) {
      setInputReadOnly(defaultReadOnly)
      changeClearButtonShow(false)
    } else {
      setInputReadOnly(tof)
    }
  }

  const changeButtonDisable = (tof) => {
    if (defaultButtonDisable) {
      setButtonDisabled(defaultButtonDisable)
    } else {
      setButtonDisabled(tof)
    }
  }

  const changeClearButtonShow = (tof) => {
    if ((defaultReadOnly || globalZipCode === '')) {
      setClearButtonShow(false)
    } else {
      setClearButtonShow(tof)
    }
  }

  const changeLoading = (tof) => {
    if (tof) {
      setLoading(true)
      changeInputReadOnly(true)
      changeButtonDisable(true)
      changeClearButtonShow(false)
    } else {
      setLoading(false)
      changeInputReadOnly(false)
      changeButtonDisable(false)
      changeClearButtonShow(true)
    }
  }

  const changeVerifiedFlag = (tof) => {
    setVerifiedFlag(tof);
  }

  const changeNotValidFlag = (tof) => {
    setNotValidFlag(tof)
  }

  const handleErrorMessage = () => {
    let stringLength = globalZipCode.length

    if (stringLength === 0) {
      if (defaultIsRequired) {
        changeErrorMessage('Enter a ZipCode')
      } else {
        changeErrorMessage('')
      }
    } else if (stringLength < defaultMinText) {
      changeErrorMessage('Write atleast 4 characters')
    } else {
      changeErrorMessage('')
    }

    // if (stringLength === 0) {
    //   if (defaultErrorMessage) {
    //     setErrorMessage(defaultErrorMessage)
    //   } else if (defaultIsRequired) {
    //     setErrorMessage('Enter a ZipCode')
    //   } else {
    //     setErrorMessage('')
    //   }
    // } else if (stringLength < defaultMinText) {
    //   if (defaultErrorMessage) {
    //     setErrorMessage(defaultErrorMessage)
    //   } else {
    //     setErrorMessage('Write atleast 4 characters')
    //   }
    // } else {
    //   setErrorMessage('')
    // }
  }

  const handleDisableButton = (stringLength) => {
    if (!(stringLength === 0)) {
      if (stringLength >= 4) {
        changeButtonDisable(false)
      } else {
        changeButtonDisable(true)
      }
    }
  }

  const handleClearShow = (stringLength) => {
    if (stringLength === 0) {
      changeClearButtonShow(false)
    } else if (stringLength > 0) {
      changeClearButtonShow(true)
    }
  }

  const checkForAlreadyVerified = (zipcode) => {
    if (alreadyVerifiedZipcodes.includes(zipcode)) {
      changeVerifiedFlag(true);
      // changeButtonDisable(true)
    }
  }

  const handleZipCodeChange = ({ zipcode, byDefaultValue = false }) => {

    changeVerifiedFlag(false);
    changeNotValidFlag(false)
    changeErrorFlag(false)

    changeZipCode(zipcode)
    onChanged({ zipcode, byDefaultValue })

    let stringLength = zipcode.length

    handleErrorMessage(stringLength)
    handleDisableButton(stringLength)
    handleClearShow(stringLength)

    checkForAlreadyVerified(zipcode)
  }

  const onTextFieldChange = e => {
    let zipcode = e.target.value
    handleZipCodeChange({ zipcode })
  }


  const onClearBtnClick = () => {

    changeVerifiedFlag(false);
    changeNotValidFlag(false)
    changeErrorFlag(false)

    changeZipCode('')
    onChanged('')

    changeClearButtonShow(false)
    changeButtonDisable(true)
    changeVerifiedFlag(false)
  }

  const onVerifyBtnClick = () => {
    verifyProcess({ zipcode: getZipCode })
  }

  const verifyProcess = ({ zipcode, byDefaultValue = false }) => {

    return new Promise((resolve, reject) => {

      changeLoading(true)
      API.getVerifyAPI(zipcode, defaultCountryCode).then(response => {

        if (response === null) {
          changeErrorFlag(true)
        } else {
          const [resObj] = response
          const { state } = resObj
          const cityList = getCityFromList(response)

          onVerify({
            status: 'success',
            zipcode,
            state,
            cityList,
            byDefaultValue
          })

          changeVerifiedFlag(true)
          changeButtonDisable(true)
          setAlreadyVerifiedZipcodes(ps => {
            ps.push(zipcode)
            return ps
          })
        }
        changeLoading(false)
        resolve()
      }).catch(error => {
        onVerify({
          status: 'error',
          zipcode,
          error
        })
        changeErrorFlag(true)
        changeNotValidFlag(true)
        changeLoading(false)
        reject()
      })
    })



  }

  useEffect(() => {
    if (defaultZipCode) {
      handleZipCodeChange({
        zipcode: defaultZipCode,
        byDefaultValue: true
      })
      verifyProcess({
        zipcode: defaultZipCode,
        byDefaultValue: true
      }).then(() => {
        changeButtonDisable(true)
      })
    }
  }, [])

  useEffect(() => {
    handleZipCodeChange({
      zipcode: defaultZipCode,
      byDefaultValue: true
    })
  }, [defaultZipCode])

  // useEffect(() => {

  //   let value = defaultZipCode
  //   let stringLength = value.length

  //   changeVerifiedFlag(false);
  //   setNotValidFlag(false)
  //   setErrorFlag(false)

  //   changeZipCode(value)

  //   handleErrorMessage(stringLength)
  //   handleDisableButton(stringLength)
  //   handleClearShow(stringLength)

  // }, [defaultZipCode])

  useEffect(() => {
    setErrorMessage(defaultErrorMessage)
  }, [defaultErrorMessage])

  useEffect(() => {
    changeErrorFlag(defaultErrorFlag)
  }, [defaultErrorFlag])

  useEffect(() => {
    changeInputReadOnly(defaultReadOnly)
  }, [defaultReadOnly])

  return {
    // state hooks
    getZipCode,
    getErrorMessage,
    errorFlag,
    inputReadOnly,
    buttonDisabled,
    clearButtonShow,
    loading,
    verifiedFlag,
    notValidFlag,

    // functions
    onTextFieldChange,
    onClearBtnClick,
    onVerifyBtnClick,

    // from props
    defaultZipCode,
    defaultMinText,
    defaultMaxText,

    defaultErrorMessage,
    defaultErrorFlag,

    defaultIsRequired,

    onVerify,
    onBlur,

    height,
    width,
    fontSize,
    borderRadius,

    name,

    defaultReadOnly,

    ...restProps
  }
}

export { useZipCodeBox }
