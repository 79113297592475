import {
  FormGroup,
  Box,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core"
import { CloseIcon } from "@iconsGallery"
import { Label, RequiredStar, VerifyButton, ErrorMsg } from "./styles"
import { coloringButton, buttonContent } from "./deps"

export const renderLabel = (props) => {
  const { defaultIsRequired, height, fontSize } = props
  return (
    <Label height={height} fontSize={fontSize}>
      Zip Code{defaultIsRequired && <RequiredStar>*</RequiredStar>}
    </Label>
  )
}

export const renderZipField = (props) => {
  const {
    // state hooks
    getZipCode,
    getErrorMessage,
    errorFlag,
    inputReadOnly,
    buttonDisabled,
    clearButtonShow,
    loading,
    verifiedFlag,
    notValidFlag,

    // functions
    onTextFieldChange,
    onClearBtnClick,
    onVerifyBtnClick,
    onBlur,

    // from props
    name,
    defaultMaxText,

    height,
    width,
    fontSize,
    borderRadius,
  } = props

  const sx = {
    height,
    width,
    fontSize,
  }

  return (
    <div>
      <FormGroup row>
        <Box display="flex" flexDirection="column">
          <TextField
            name={name}
            variant="outlined"
            value={getZipCode}
            onChange={onTextFieldChange}
            error={errorFlag}
            onBlur={onBlur}
            InputProps={{
              inputProps: { maxLength: defaultMaxText },
              readOnly: inputReadOnly,
              style: {
                borderTopLeftRadius: `${borderRadius}px`,
                borderBottomLeftRadius: `${borderRadius}px`,
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
                height: `${sx.height}px`,
                width: `${Number(sx.width)}px`,
                minWidth: "150px",
                overflow: "hidden",
              },
              endAdornment: (
                <>
                  {clearButtonShow && (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={onClearBtnClick}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
          {getErrorMessage && <ErrorMsg {...sx}>{getErrorMessage}</ErrorMsg>}
        </Box>
        <VerifyButton
          {...sx}
          variant="contained"
          disableElevation
          disabled={buttonDisabled}
          style={{
            backgroundColor: coloringButton(
              verifiedFlag,
              loading,
              notValidFlag
            ),
            border: "1px solid gray",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            borderTopRightRadius: `${borderRadius}px`,
            borderBottomRightRadius: `${borderRadius}px`,
          }}
          onClick={onVerifyBtnClick}
        >
          {buttonContent(verifiedFlag, loading, notValidFlag)}
        </VerifyButton>
      </FormGroup>
    </div>
  )
}
