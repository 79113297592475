import React from "react"
import * as render from "./renderProps"
import * as StyledDOM from "./styles"
import * as FIXTURE from "./fixture"
import { Grid, Typography as MuiTypography } from "@material-ui/core"
import useOperationHoursKit from "./useOperationHoursKit"
import styled from "styled-components/macro"
import { spacing } from "@material-ui/system"

function OperationHoursKit(props) {
  const Typography = styled(MuiTypography)(spacing)
  const stateProps = useOperationHoursKit(props)
  return (
    <StyledDOM.StyledOperationHoursKitMainWrapper>
      <Grid item style={{ marginBottom: "10px" }}>
        <Typography align="left" style={{ fontWeight: "bold" }}>
          {"Operating Hours"}
          <span style={{ color: "red" }}>{stateProps?.edit ? "*" : ""} </span>
        </Typography>
      </Grid>
      {render?.renderHeaderContainer(stateProps)}
      {render?.renderContainer(stateProps)}
    </StyledDOM.StyledOperationHoursKitMainWrapper>
    // <></>
  )
}

export { OperationHoursKit }

export default OperationHoursKit
