import { QuestionIcon } from "@iconsGallery"
import { Wrapper, QueryPara, IconDiv, List, SpaceDiv } from "./styles"

function QuestionBlock(props) {

  const {
    query = ''
  } = props

  return (
    <Wrapper width="fit-content">
      <QueryPara>{query}</QueryPara>
      <IconDiv><QuestionIcon /></IconDiv>
    </Wrapper>
  )
}

function AnswerBlock(props) {

  const {
    answerList = []
  } = props

  return (
    <Wrapper width="400px">
      <SpaceDiv>
        <List>
          {answerList.map(li => <li>{li}</li>)}
        </List>
      </SpaceDiv>
    </Wrapper>
  )
}

export { QuestionBlock, AnswerBlock }