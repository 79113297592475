//customized components are imported like <Cusotmized.componentName/> from styles folder
import React from "react"

import { Grid, List } from "@material-ui/core"

import * as Customized from "../../styles/components/Footer/styles.js"

function Footer() {
  let time = new Date().getFullYear()
  return (
    <Customized.Wrapper>
      <Grid
        container
        item
        spacing={0}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        justify="space-between"
        direction="row"
      >
        <Grid item xs={12} md={6} lg={6} sm={8}>
          {/* <List>
            <Customized.ListItem
              button={true}
              component="a"
              href="/support"
              data-testid="support"
            >
              <Customized.ListItemText primary="Support" />
            </Customized.ListItem>
            <Customized.ListItem
              button={true}
              component="a"
              href="/help"
              data-testid="helpCenter"
            >
              <Customized.ListItemText primary="Help Center" />
            </Customized.ListItem>
            <Customized.ListItem
              button={true}
              component="a"
              href="/privacy"
              data-testid="privacy"
            >
              <Customized.ListItemText primary="Privacy" />
            </Customized.ListItem>
            <Customized.ListItem
              button={true}
              component="a"
              href="/terms-of-service"
              data-testid="terms"
            >
              <Customized.ListItemText primary="Terms of Service" />
            </Customized.ListItem>
          </List> */}
        </Grid>
        <Grid item xs={12} md={6} lg={6} sm={4} style={{ textAlign: "right" }}>
          <List>
            <Customized.ListItem component="a">
              <Customized.CopyrightText
                primary={`${time} ${window["brandName"]} ADMIN`}
              />
            </Customized.ListItem>
          </List>
        </Grid>
      </Grid>
    </Customized.Wrapper>
  )
}

export default Footer
