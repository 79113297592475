import React from "react"
import { Grid, TextField, ListItem, ListItemText } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import * as Adm from "@adm"
import DigitTable from "./DigitTable"
import DigitImageTable from "./DigitImageTable"
import { VendorSwaperLens } from "@iconsGallery"

const EmptyProductStyles = { fontWeight: "500", fontSize: "17px" }
const EmptyProductImage = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "30%" }


const renderFieldItemId = (props) => {
  const {
    setCurrentVendorItem = () => "",
    getCurrentVendorItem = [],
    getVendorOpen,
    setVendorOpen,
    classes,
    getToVendorList,
    getToVendorIdList
  } = props

  // let productRawList = [
  //   {
  //     item_id: "10002",
  //     stockCode: "8886467122408",
  //     barcodeValue: "8886467122408",
  //     title: "Pringles Sour Cream Onion (107) g",
  //     imageURL:
  //       "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-images/7a3491b7-fe6e-4dcb-adac-731d73f7e72f/product/252fae9c-dff6-4557-912f-1fea76e3e9ae___Pringles%20Sour%20Cream%20Oni.jpeg?sv=2023-11-03&se=2025-02-07T23%3A59%3A59Z&sr=b&sp=racwd&sig=oIbObV60Jh6gniXfk4ACRhev%2BcDdZbWIL6BlPjB6tag%3D",
  //     imageUrl:
  //       "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-images/7a3491b7-fe6e-4dcb-adac-731d73f7e72f/product/252fae9c-dff6-4557-912f-1fea76e3e9ae___Pringles%20Sour%20Cream%20Oni.jpeg?sv=2023-11-03&se=2025-02-07T23%3A59%3A59Z&sr=b&sp=racwd&sig=oIbObV60Jh6gniXfk4ACRhev%2BcDdZbWIL6BlPjB6tag%3D",
  //   },
  //   {
  //     item_id: "10008",
  //     stockCode: "8901058890709",
  //     barcodeValue: "8901058890709",
  //     title: "Maggi Masala Cuppa 70 g",
  //     imageURL:
  //       "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-images/7a3491b7-fe6e-4dcb-adac-731d73f7e72f/product/88da171b-2698-4c8b-bde2-958251366231___Maggi%20Masala%20Cuppa%2070%20g.jpeg?sv=2023-11-03&se=2025-03-13T23%3A59%3A59Z&sr=b&sp=racwd&sig=SperCxwgZ4wQWVoTwJophdHqDIA%2F7DRU%2FqLV4zNUmnM%3D",
  //     imageUrl:
  //       "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-images/7a3491b7-fe6e-4dcb-adac-731d73f7e72f/product/88da171b-2698-4c8b-bde2-958251366231___Maggi%20Masala%20Cuppa%2070%20g.jpeg?sv=2023-11-03&se=2025-03-13T23%3A59%3A59Z&sr=b&sp=racwd&sig=SperCxwgZ4wQWVoTwJophdHqDIA%2F7DRU%2FqLV4zNUmnM%3D",
  //   },
  //   {
  //     item_id: "10009",
  //     stockCode: "8902579002039",
  //     barcodeValue: "8902579002039",
  //     title: "Appy Fizz 250 ml",
  //     imageURL:
  //       "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-images/7a3491b7-fe6e-4dcb-adac-731d73f7e72f/product/5cc2e9ec-0bc4-49b3-aef1-6c4a29f2a3c0___appy.jpeg?sv=2023-11-03&se=2025-01-31T23%3A59%3A59Z&sr=b&sp=racwd&sig=k1z3Pfdxoxrh0awcL1WeTM4H6n52BPQXoQ4mUkrILqM%3D",
  //     imageUrl:
  //       "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-images/7a3491b7-fe6e-4dcb-adac-731d73f7e72f/product/5cc2e9ec-0bc4-49b3-aef1-6c4a29f2a3c0___appy.jpeg?sv=2023-11-03&se=2025-01-31T23%3A59%3A59Z&sr=b&sp=racwd&sig=k1z3Pfdxoxrh0awcL1WeTM4H6n52BPQXoQ4mUkrILqM%3D",
  //   },
  // ]

  const renderOptions = (option, props) => {
    let itemId = props
    const [_productItemKit] = getToVendorList.filter((itm) =>
      itm.company_id.includes(itemId)
    )
    const {
      company_name = "",
      company_logo_url = "",
      company_id = "",
    } = _productItemKit
    return (
      <ListItem
        style={{
          paddingLeft: "0px",
        }}
      >
        <img
          src={company_logo_url || "/static/img/noproducts.png"}
          alt={company_name}
          style={{
            width: "100%",
            height: "auto",
            marginRight: 5,
            maxWidth: 70,
            maxHeight: 70,
            minWidth: 70,
            minHeight: 70,
          }}
        />
        <ListItem
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            width: "fit-content",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <ListItemText
            primary={`Vendor: ${company_name}`}
            primaryTypographyProps={{
              style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "inline-block",
                whiteSpace: "nowrap",
                fontWeight: "bold",
                margin: 0,
              },
            }}
            style={{ margin: 0 }}
          />

          <ListItemText
            style={{ margin: 0 }}
            primary={`Vendor Id : ${company_id}`}
            primaryTypographyProps={{
              style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "inline-block",
                whiteSpace: "nowrap",
                margin: 0,
              },
            }}
          />
        </ListItem>
      </ListItem>
    )
  }
  const filterOptions = createFilterOptions({
    stringify: (name) => {
      let itemId = name
      const [_productItemKit] = getToVendorList.filter((itm) =>
        itm.company_id.includes(itemId)
      )
      const { company_name = "", company_id = "" } = _productItemKit
      return `${company_name} ${itemId} ${company_id}`
    },
  })

  return (
    <Grid>
      <Autocomplete
        className={`cls-field-form-kit`}
        classes={{ option: classes.option }}
        renderOption={(props, option) => renderOptions(option, props)}
        filterSelectedOptions={true}
        filterOptions={filterOptions}
        options={getToVendorIdList}
        onChange={(e, val) => {
          setCurrentVendorItem([val])
          setVendorOpen(false)
        }}
        open={getVendorOpen}
        disableClearable={true}
        value={getCurrentVendorItem.length > 0 ? getCurrentVendorItem[0] : ""}
        onOpen={(e) => {
          setVendorOpen(true)
        }}
        onClose={(e) => {
          setVendorOpen(false)
        }}
        // ListboxProps={{
        //   onScroll: (e) => handleScroll(e),
        // }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              className={classes.autoCompleteHeight}
              placeholder="Select the Vendor"
            />
          )
        }}
      />
    </Grid>
  )
}

export const renderHeader = (props) => {
  const {
    getCurrentImageList,
    getCurrentVendorItem = [],
    handleSwapVendor = () => "",
    getSelectedItem = []
  } = props || {}

  const selectAllBtnAttr = {
    variant: "contained",
    color: "primary",
    className: `cls-button-kit ${getCurrentVendorItem?.length === 0 || getSelectedItem?.length === 0 ? "cls-disabled" : ""}`,
    onClick: handleSwapVendor,
    disabled: getCurrentVendorItem?.length === 0 || getSelectedItem?.length === 0 ? true : false,
  }

  return (
    <div className="cls-main-header-kit">
      <div
        style={{ fontSize: 20, fontWeight: "bold" }}
      >{`Storage Name`}</div>
      <div
        style={{ display: "flex" }}
        className={`cls-header-right-kit ${getSelectedItem.length > 0
          ? ""
          : "cls-selected-storage-list-kit-disable"
          }`}
      >
        {renderFieldItemId(props)}
        <Adm.Button {...selectAllBtnAttr}>{"Swap Vendor"}</Adm.Button>
      </div>
    </div>
  )
}
export const renderLeftSide = (props) => {
  const {
    getCurrentImageList,
    setCurrentImageList,
    getOverAllImageList,
    setOverAllImageList,
    getCurrentStorageItem,
    setCurrentStorageItem,
    getInitLoad,
    setInitLoad,
    setStorageUnitId,
    getStorageUnitId,
    setSelectedItem,
    isRerender,
    setRerender,
    storageGridTableData,
    setStorageGridTableData,
    getVendorId,
  } = props
  return (
    <div className="cls-swap-left-panel">
      <DigitTable
        getRawList={(props) => {
          setCurrentStorageItem([])
        }}
        getInitLoad={getInitLoad}
        setInitLoad={setInitLoad}
        currentList={getCurrentStorageItem}
        setStorageUnitId={setStorageUnitId}
        getStorageUnitId={getStorageUnitId}
        isRerender={isRerender}
        setRerender={setRerender}
        setSelectedItem={setSelectedItem}
        storageGridTableData={storageGridTableData}
        setStorageGridTableData={setStorageGridTableData}
        getVendorId={getVendorId}
        onUpdate={(innerProps) => {
          const { data = [], raw = [] } = innerProps
          const [_data = {}] = data
          let lcData = raw.filter((itm) => itm.isConnected)
          lcData = lcData.map((itm) => itm.storage_unit_id)
          setCurrentImageList([])
          setCurrentStorageItem([...lcData])
        }}
      />
    </div>
  )
}

let renderImageHeaderLeftSearch = (props) => {
  const { getSearchImage = "", setSearchImage, setTriggerSearchImag } = props

  const selectAllBtnAttr = {
    variant: "contained",
    color: "primary",
    onClick: () => {
      // setTriggerSearchImag({ search: getSearchImage })
    },
    // disabled: getSearchImage.length > 0 === false,
    className: `cls-header-left-kit`,
  }
  return (
    <div>
      <TextField
        style={{ marginRight: 10 }}
        inputProps={{
          style: {
            height: "23px",
            minHeight: "23px",
            minWidth: 260,
            padding: 6,
          },
        }}
        variant="outlined"
        placeholder="Search by Product Name/Barcode/SKU"
        onChange={(e, val) => {
          let value = e.target.value
          setSearchImage(value.length > 3 ? value : "")
        }}
      />
      {/* <Adm.Button {...selectAllBtnAttr}>{"Search"}</Adm.Button> */}
    </div>
  )
}
let renderImageHeaderRightSelect = (props) => {
  const {
    setCurrentImageList,
    getOverAllImageList,
    getCurrentImageList,
    getSelectedItem,
    setSelectedItem,
    clickedSelectAll
  } = props
  const selectAllBtnAttr = {
    variant: "outlined",
    onClick: clickedSelectAll, // setCurrentImageList([...getOverAllImageList])
  }
  return (
    <div className="cls-swap-right-header">
      <Adm.Button {...selectAllBtnAttr}>{"Select All"}</Adm.Button>
      <div style={{ padding: 10, fontWeight: "bold" }}>{`${getSelectedItem.length || 0} Selected`}</div>
      {getSelectedItem.length > 0 && (
        <div
          style={{
            padding: 10,
            fontWeight: "bold",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => {
            clickedSelectAll(false)
            //setCurrentImageList([])
          }}
        >{`Clear All`}</div>
      )}
    </div>
  )
}
let renderImageListHeader = (props) => {
  const {
    setCurrentImageList,
    getOverAllImageList,
    getCurrentImageList,
  } = props
  const selectAllBtnAttr = {
    variant: "outlined",
    onClick: () => setCurrentImageList([...getOverAllImageList]),
  }
  return (
    <div
      style={{
        display: "flex",
        marginLeft: 10,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {renderImageHeaderLeftSearch(props)}
      {renderImageHeaderRightSelect(props)}
    </div>
  )
}
export const renderRightSide = (props) => {
  const {
    getCurrentImageList,
    setCurrentImageList,
    getOverAllImageList,
    setOverAllImageList,
    getCurrentStorageItem,
    getSearchImage,
    getStorageUnitId,
    getVendorId,
    setSelectedItem,
    productGridTableData,
    setProductGridTableData,
  } = props

  return (
    <div
      className={`cls-swap-right-panel ${getCurrentImageList.length > 0 ? "cls-selected-list" : ""
        }`}
    >
      <div className="cls-swap-right-sub-panel">
        {getCurrentStorageItem.length > 0 ? (
          <>
            {renderImageListHeader(props)}
            <DigitImageTable
              searchTrigger={getSearchImage}
              refreshList={getCurrentStorageItem}
              currentList={getCurrentImageList}
              getStorageUnitId={getStorageUnitId}
              getVendorId={getVendorId}
              setSelectedItem={setSelectedItem}
              productGridTableData={productGridTableData}
              setProductGridTableData={setProductGridTableData}
              getRawList={(props) => {
                let lcData = props?.data.map((itm) => itm.storage_unit_id)
                setOverAllImageList([...lcData])
              }}
              onUpdate={(innerProps) => {
                const { data = [], raw = [] } = innerProps
                const [_data = {}] = data
                let lcData = raw.filter((itm) => itm.isConnected)
                lcData = lcData.map((itm) => itm.storage_unit_id)
                setCurrentImageList([...lcData])
              }}
            />
          </>
        ) : (
          <div style={EmptyProductImage}>
            <div><VendorSwaperLens /></div>
            <div style={EmptyProductStyles}>Select the storage to view products</div>
          </div>
        )}
      </div>
    </div>
  )
}
