import React, { useState, useEffect } from "react"
import { withTheme } from "styled-components/macro"
import {
  Divider,
  Grid,
  Typography,
  Collapse,
  Card,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import {
  CheckCircleIcon,
  ChevronUp,
  ChevronDown,
  Slash,
  Edit,
  PaymentIcon,
} from "@iconsGallery"
import { useTranslation } from "react-i18next"
import LocationTreeComponent from "./LocationTree"
import { useDispatch, useSelector } from "react-redux"
import * as ServiceAPI from "@services"
import _ from "lodash"
import * as Features from "@features"
import * as Adm from "@adm"
import * as constantsAPI from "@constants"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

function EditLocationHierarchyKit(props) {
  const {
    parentUseStyles = {},
    setFieldValue = () => { },
    errors = {},
    touched = {},
    setAllowPrompt = () => { },
    isWriteAllowed = false,
    locationType = {},
    setLocationType = () => { },
    locationSize = {},
    setLocationSize = () => { },
    oldState = {},
    storeList = [],
    setStoreType = () => { },
    storeType = {},
    paymentGatewayList = [],
    setPaymentType = () => { },
    paymentType = {},
    accountList = [],
    accountType,
    setAccountType = () => { },
    storeId = "",
    edit = false,
    setEdit = () => { },
    isAnyChanges = false,
    setAnyChanges = () => { },
    locationTypeError = false,
    setLocationTypeError = () => { },
    paymentTypeError = false,
    setPaymentTypeError = () => { },
    accountTypeError = false,
    setAccountTypeError = () => { },
    locationSizeError = false,
    setLocationSizeError = () => { },
    locationId = "",
    values = {},
    getIsRequiredPreAuthAmount = {},
    setIsRequiredPreAuthAmount = () => '',
    handleBlur = () => ''
  } = props

  const [collapseOpen, setCollapseOpen] = useState(false)
  const { t } = useTranslation()
  const [reset, setReset] = useState(false)
  const [storeTypeError, setStoreTypeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [defaultData, setDefaultData] = useState({})

  useEffect(() => {
    setLocationSize(props?.locationSize)
    setLocationType(props?.locationType)
    setStoreType(props?.storeType)
    setPaymentType(props?.paymentType)
    setDefaultData((e) => {
      return {
        locationSize: props.locationSize,
        locationType: props?.locationType,
        storeType: props?.storeType,
        paymentType: props?.paymentType,
      }
    })
  }, [locationSize, locationType, storeType, paymentType])

  const resetData = () => {
    setEdit(false)
    setReset(true)
  }

  const updateData = async () => {
    if (locationType === {}) {
      setLocationTypeError(true)
      return
    }
    if (
      localStorage.getItem("tenantPaymentConfigEnable") === "true" &&
      paymentType === ""
    ) {
      setPaymentTypeError(true)
      return
    }
    if (
      localStorage.getItem("tenantPaymentConfigEnable") === "true" &&
      accountType === ""
    ) {
      setAccountTypeError(true)
      return
    } else if (
      !_.every(
        locationSize,
        (x, i, k) =>
          k.lengthValue !== "" && k.heightValue !== "" && k.widthValue !== ""
      )
    ) {
      setLocationSizeError(true)
      return
    } else if (
      _.every(
        locationSize,
        (x, i, k) =>
          k.lengthValue === "0" || k.heightValue === "0" || k.widthValue === "0"
      )
    ) {
      setLocationSizeError(true)
      return
    } else {
      setEdit(false)
      setLoading(true)
      setAlert(false)
      setLocationSizeError(false)
      setLocationTypeError(false)
      let body = {
        ...props.sendBackData,
        locationType: locationType,
        locationSize: locationSize,
        storeType:
          locationType?.name === "Store" ? storeType : { id: "", name: "" },
        paymentMethodType:
          locationType?.name === "Store" ? paymentType?.id : "",
        paymentAccountName:
          locationType?.name === "Store" ? accountType?.id : "",
        paymentMode: locationType?.name === "Store" ? accountType?.mode : "",
      }

      let response = await ServiceAPI.fetchStoreCommonData(
        API_END_POINTS.updateLocation,
        body
      )
      await setDefaultData({
        locationSize: _.get(response, "data.data.locationSize", {}),
        locationType: _.get(response, "data.data.locationType", {}),
        storeType: _.get(response, "data.data.storeType", {}),
      })
      await setLocationSize(_.get(response, "data.data.locationSize", {}))
      await setLocationType(_.get(response, "data.data.locationType", {}))
      await setAlertType(_.get(response, "data.status", "error"))
      await setAlertMessage(
        _.get(
          response,
          response?.status === 200 ? "data.message" : "response.data.message",
          "Update Failed"
        )
      )
      await setAlert(true)
      await setLoading(false)
      setAnyChanges(false)
    }
    //}
    props.fetchData(locationId)
  }
  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Adm.BackdropOverlay open={loading} />
        <Card
          style={{
            width: "100%",
            borderRadius: "8px",
          }}
        >
          <>
            <Grid
              container
              direction="row"
              alignContent="center"
              alignItems="center"
              style={{
                justifyContent: "space-between",
                paddingLeft: "15px",
                height: "30px",
                padding: "20px",
              }}
            >
              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  <IconButton>
                    <PaymentIcon />
                  </IconButton>
                  {t("STORE PAYMENT INFORMATION ")}
                </Typography>
              </Grid>
            </Grid>

            <LocationTreeComponent
              parentUseStyles={parentUseStyles}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              edit={edit}
              locationSize={locationSize}
              locationType={locationType}
              locations={props.locationTypeList}
              setLocationSize={setLocationSize}
              setLocationType={setLocationType}
              locationTypeError={locationTypeError}
              locationSizeError={locationSizeError}
              setAllowPrompt={setAllowPrompt}
              setAnyChanges={setAnyChanges}
              isDetailView={true}
              isAnyChanges={isAnyChanges}
              storeList={storeList}
              setStoreType={setStoreType}
              storeType={storeType}
              storeTypeError={storeTypeError}
              SDCConfigured={oldState?.SDCConfigured}
              paymentGatewayList={paymentGatewayList}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              paymentTypeError={paymentTypeError}
              accountList={accountList}
              accountType={accountType || ""}
              setAccountType={setAccountType}
              accountTypeError={accountTypeError}
              locationId={locationId}
              values={values}
              lg={2}
              lg1={4}
              lg2={6}
              getIsRequiredPreAuthAmount={getIsRequiredPreAuthAmount}
              setIsRequiredPreAuthAmount={setIsRequiredPreAuthAmount}
              handleBlur={handleBlur}
            />
          </>
        </Card>
        <>
          {alert ? (
            <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
          ) : null}
        </>
      </Grid>
    </>
  )
}
const EditLocationHierarchy = withTheme(EditLocationHierarchyKit)
export { EditLocationHierarchy }
export default EditLocationHierarchy
