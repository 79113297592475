import Slider from "@material-ui/core/Slider"
import styled from "styled-components"

export const StyledWrapper = styled("div")`
  padding: 27px;
  padding-top: 61px;
  height: 112px;
  min-width: 500px;
  box-sizing: border-box;

  .cls-static-suffix {
    width: 30px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: -49px;
    left: 0px;
  }
  .cls-static-suffix2 {
    position: absolute;

    left: 13px;
    top: 21px;
  }

  .cls-static-thumb {
    height: 20px;
    width: 20px;
    background-color: #afafaf;
    border-radius: 50%;
    border-bottom-right-radius: 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: -10px;
    left: 15px;
  }
`
export const StyledDOMSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  // width: 300,
  "span [class^='PrivateValueLabel-circle']": {
    backgroundColor: "unset !important",
    "span[class^='PrivateValueLabel-label']": {
      color: "black",
      textAlign: "center",
    },
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#D14CAD",
    borderRadius: "50%",
    borderBottomRightRadius: "0px",
    transform: "rotate(45deg) translateX(10px)",
    transformOrigin: "0% 0%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    top: "-25px",

    "&:hover, &.Mui-focusVisible": {
      boxShadow: "none",
    },
  },
  "& .MuiSlider-rail": {
    opacity: 0.1,
    backgroundColor: "#0c0c0c",
    height: 6,
  },
  "& .MuiSlider-mark": {
    backgroundColor: "gray",
    height: 0,
    width: 0,
  },
  "& .MuiSlider-track": {
    backgroundColor: "#DBABCC",
    height: 6,
  },
  "& .MuiSlider-valueLabel": {
    transform: "rotate(-45deg) translate(1px, -7px) !important",
    background: "unset !important",
    color: "black",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "2px 6px",
    borderRadius: "4px",
  },
}))
